import React from 'react'

import styles from './index.module.scss'


const Title = ({ h1, h2, sub, right }) => {
    return (
        <div className={styles.title}>
            <div className={styles.box}>

                {h1 && (
                    <h1>{h1}</h1>
                )}

                {h2 && (
                    <h2>{h2}</h2>
                )}

                {sub && (
                    <div className={styles.sub}>{sub}</div>
                )}

                {right && (
                    <div className={styles.right}>{right}</div>
                )}
            </div>
        </div>
    )
}

export default Title