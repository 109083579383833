import React from 'react'

import { useLocation } from '@reach/router'
import Link from 'src/components/link/index'
import Image from 'src/components/image/index'
import CampaignButton from 'src/components/campaign/button'
import AdmissionButton from 'src/components/admission/button'

import styles from './index.module.scss'


const ReadyToGo = () => {

    const { pathname } = useLocation()

    return (
        <div className={styles.readyToGo}>
            <div className={styles.background}>
                <Image src="/readytogo.jpg" alt="" />
            </div>
            <div className={styles.inner}>
                <div className={styles.box}>
                    <div className={styles.title}>READY TO GO?</div>
                    <div className={styles.buttons}>
                        <AdmissionButton className={styles.forAdmission} />

                        {pathname === '/beginners/' ? (
                            <CampaignButton className={styles.forCampaign} />
                        ) : (
                            <Link className = {styles.forBeginners} to="/beginners/">ビギナーの方へ</Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReadyToGo