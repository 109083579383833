import React from 'react'

import styles from './index.module.scss'


const Inner = ({ className, children }) => {

    const classNames = styles.inner + (className ? ` ${className}` : ``)

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}

export default Inner